import {RouteLocationNormalized} from "vue-router";
import {useUserState} from "~/composables/useUserState";
import {useCookieApi} from "~/server/utils/useCookieApi";
import {useLogger} from "~/composables/useLogger";
import {cookieConst} from "~/consts/cookieConst";

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const userState = useUserState()
    const logger = useLogger()

    // fromパラメータの保存
    const parsedFromQuery = to.query['from'] as string
    const fromQuery = parsedFromQuery == "miyako_island" ? "bird" : parsedFromQuery
    if (fromQuery) {
        userState.setFrom(fromQuery)
    }
    // fromパラメータのCookie保存
    const fromCookie = useCookie(cookieConst.NAME_FROM).value
    if (fromQuery && fromQuery != fromCookie) {
        useCookieApi().execute({ name: cookieConst.NAME_FROM, value: fromQuery })
            .then(() => logger.debug(`fromパラメータ(${fromQuery})のCookie保存に成功`))
            .catch((event) => logger.error(`fromパラメータ(${fromQuery})のCookie保存に失敗`, event))
    }

    // uuidのCookie保存
    const uuidCookie = useCookie(cookieConst.NAME_UUID).value
    if (!uuidCookie && to.path == "/") {
        useCookieApi().execute({ name: cookieConst.NAME_UUID })
            .then(() => logger.debug(`uuidのCookie保存に成功`))
            .catch((event) => logger.error(`uuidのCookie保存に失敗`, event))
    }
})
