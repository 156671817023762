import {useMyFetch} from "~/composables/useMyFetch";
import dayjs from "dayjs";
import {AreaType} from "~/utils/useBirdUtil";

type AppLogType = "view" | "click" | "longtap" | "alert"
type AppLogPageStatus = "200" | "404"
export type AppLogQuery = {
    type: AppLogType
    time?: string // CloudFrontでキャッシュさせないようにしているが、設定が変更された場合を考慮して念の為
    pagePath?: string
    pageStatus?: AppLogPageStatus
    pageQuery?: string
    fromPagePath?: string
    fromPageStatus?: AppLogPageStatus
    fromPageQuery?: string
    clickName?: string
    eventName?: string
    areaType?: AreaType
}

export const useAppLogApi = () => {
    return {
        execute,
        clickEvent: <T>(clickName: string) => {
            execute({type: "click", clickName: clickName})
        },
        event: <T>(type: AppLogType, eventName: string) => {
            execute({type: type, eventName: eventName})
        }
    }
}

function execute<T>(query: AppLogQuery) {
    const routing = useRoute()
    query.time = query.time ?? dayjs().valueOf().toString()
    query.pagePath = query.pagePath ?? routing.path
    const params = new URLSearchParams(query)
    const url = `/api/v1/app/log?${params.toString()}`
    useMyFetch(url).get()
}
