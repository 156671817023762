import {AfterFetchContext, BeforeFetchContext, createFetch, UseFetchReturn} from "@vueuse/core";
import {headerConst} from "~/consts/headerConst";
import {useUserState} from "~/composables/useUserState";

const defaultFetchOptions = (): RequestInit => {
    return {
        cache: 'no-cache',
        mode: 'cors',
        credentials: 'include', // Cookieを扱うために設定
    }
}

export const useMyFetch = <T>(url: string): UseFetchReturn<T> & PromiseLike<UseFetchReturn<T>> => {
    const config = useRuntimeConfig()
    const userState =  useUserState()
    const fetchOptions = {...defaultFetchOptions()}
    fetchOptions.headers = {
        ...fetchOptions.headers,
        "x-ntj-uuid": userState.getUUID() ?? "",
        "x-ntj-from": userState.getFrom() ?? ""
    }

    const myFetch = createFetch({
        baseUrl: config.public.apiUrl,
        fetchOptions: fetchOptions,
        options: {
            beforeFetch: (ctx: BeforeFetchContext) => {
                useLogger().debug(`fetch start. method=${ctx.options.method}, url=${ctx.url}, body=${ctx.options.body ?? ""}`)
            },
            afterFetch: (ctx: AfterFetchContext): AfterFetchContext => {
                useLogger().debug(`fetch completed. status=${ctx.response.status}, url=${ctx.response.url}`)
                return ctx
            }
        }
    })
    return myFetch<T>(url)
}
