import {Ref} from "vue";
import {useLogger} from "~/composables/useLogger";
import {useAppLogApi} from "~/server/utils/useAppLogApi";

type alertType = "error" | "success" | "warning" | "info"
type State = {
    alerting: boolean
    type: alertType
    message: string
}
const DEFAULT_TIMEOUT = 3000

export const useAlert = () => {
    const state = useState<State>("alert", () => ({
        alerting: false,
        type: 'warning',
        message: ''
    }))
    return {
        getState: () => readonly(state.value),
        error: (message: string, timeout: number = DEFAULT_TIMEOUT, isAutoClose?: boolean) => {
            show(state, 'error', message, timeout, isAutoClose)
            useLogger().error(message)
            useAppLogApi().event('alert', message)
        },
        warning: (message: string, timeout: number = DEFAULT_TIMEOUT, isAutoClose?: boolean) => {
            show(state, 'warning', message, timeout, isAutoClose)
            useLogger().warn(message)
            useAppLogApi().event('alert', message)
        },
        info: (message: string, timeout: number = DEFAULT_TIMEOUT, isAutoClose?: boolean) => {
            show(state, 'info', message, timeout, isAutoClose)
            useLogger().info(message)
            useAppLogApi().event('alert', message)
        },
        success: (message: string, timeout: number = DEFAULT_TIMEOUT, isAutoClose?: boolean) => {
            show(state, 'success', message, timeout, isAutoClose)
            useLogger().info(message)
            useAppLogApi().event('alert', message)
        },
        hide: () => {
            state.value.alerting = false
        }
    }
}

function show(state: Ref<State>, type: alertType, message: string, timeout: number = DEFAULT_TIMEOUT, isAutoClose: boolean = true) {
    state.value.alerting = true
    state.value.type = type
    state.value.message = message
    if (isAutoClose) {
        setTimeout(() => {
            state.value.alerting = false
        }, timeout)
    }
}