import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47attention_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/attention.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47cheetos_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/cheetos.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47console_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/console.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47geolocation_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/geolocation.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47index_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/index.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47limit_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/limit.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47firstattention_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/firstattention.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47form_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/form.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47index_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/index.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47licenses_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/licenses.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47lp_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/lp.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47navi_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/navi.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47questionnaire_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/questionnaire.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47route_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/route.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47spot_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/spot.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47terms_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/terms.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47usage_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/usage.vue?macro=true";
import { default as _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47welcome_46vueMeta } from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/welcome.vue?macro=true";
export default [
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47attention_46vueMeta?.name ?? "attention",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47attention_46vueMeta?.path ?? "/attention",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/attention.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47attention_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47attention_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47attention_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/attention.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47cheetos_46vueMeta?.name ?? "cheetos",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47cheetos_46vueMeta?.path ?? "/cheetos",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/cheetos.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47cheetos_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47cheetos_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47cheetos_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/cheetos.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47console_46vueMeta?.name ?? "debug-console",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47console_46vueMeta?.path ?? "/debug/console",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/console.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47console_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47console_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47console_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/console.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47geolocation_46vueMeta?.name ?? "debug-geolocation",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47geolocation_46vueMeta?.path ?? "/debug/geolocation",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/geolocation.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47geolocation_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47geolocation_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47geolocation_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/geolocation.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47index_46vueMeta?.name ?? "debug",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47index_46vueMeta?.path ?? "/debug",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/index.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47index_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47index_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47limit_46vueMeta?.name ?? "debug-limit",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47limit_46vueMeta?.path ?? "/debug/limit",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/limit.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47limit_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47limit_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47debug_47limit_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/debug/limit.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47firstattention_46vueMeta?.name ?? "firstattention",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47firstattention_46vueMeta?.path ?? "/firstattention",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/firstattention.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47firstattention_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47firstattention_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47firstattention_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/firstattention.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47form_46vueMeta?.name ?? "form",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47form_46vueMeta?.path ?? "/form",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/form.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47form_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47form_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47form_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/form.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47index_46vueMeta?.name ?? "index",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47index_46vueMeta?.path ?? "/",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/index.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47index_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47index_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47licenses_46vueMeta?.name ?? "licenses",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47licenses_46vueMeta?.path ?? "/licenses",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/licenses.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47licenses_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47licenses_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47licenses_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/licenses.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47lp_46vueMeta?.name ?? "lp",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47lp_46vueMeta?.path ?? "/lp",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/lp.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47lp_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47lp_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47lp_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/lp.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47navi_46vueMeta?.name ?? "navi",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47navi_46vueMeta?.path ?? "/navi",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/navi.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47navi_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47navi_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47navi_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/navi.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47questionnaire_46vueMeta?.name ?? "questionnaire",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47questionnaire_46vueMeta?.path ?? "/questionnaire",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/questionnaire.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47questionnaire_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47questionnaire_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47questionnaire_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47route_46vueMeta?.name ?? "route",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47route_46vueMeta?.path ?? "/route",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/route.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47route_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47route_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47route_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/route.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47spot_46vueMeta?.name ?? "spot",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47spot_46vueMeta?.path ?? "/spot",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/spot.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47spot_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47spot_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47spot_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/spot.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47terms_46vueMeta?.name ?? "terms",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47terms_46vueMeta?.path ?? "/terms",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/terms.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47terms_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47terms_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47terms_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47usage_46vueMeta?.name ?? "usage",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47usage_46vueMeta?.path ?? "/usage",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/usage.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47usage_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47usage_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47usage_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/usage.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47welcome_46vueMeta?.name ?? "welcome",
    path: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47welcome_46vueMeta?.path ?? "/welcome",
    file: "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/welcome.vue",
    children: [],
    meta: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47welcome_46vueMeta,
    alias: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47welcome_46vueMeta?.alias || [],
    redirect: _47home_47jenkins_47agent_47workspace_47contents_47aioikickboard_47staging_47web_deploy_47aioikickboard_47pages_47welcome_46vueMeta?.redirect || undefined,
    component: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/pages/welcome.vue").then(m => m.default || m)
  }
]