<script lang="ts" setup>
const loading = useLoading()
</script>

<template>
  <div class="loading-area">
    <v-overlay
        :model-value="loading.getState().isActive"
        class="align-center justify-center"
    >
      <v-progress-circular
          indeterminate
          size="64"
          color="#4a81c7"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<style lang="scss" scoped>

</style>
