import validate from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/node_modules/nuxt/dist/pages/runtime/validate.mjs";
import accesslog_45global from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/middleware/accesslog.global.ts";
import cookie_45global from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/middleware/cookie.global.ts";
import navilog_45global from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/middleware/navilog.global.ts";
export const globalMiddleware = [
  validate,
  accesslog_45global,
  cookie_45global,
  navilog_45global
]
export const namedMiddleware = {
  termscheck: () => import("/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/middleware/termscheck.ts")
}