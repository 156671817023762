<script lang="ts" setup>
import {useAlert} from "~/composables/useAlert";

const alert = useAlert()
</script>

<template>
  <div class="alert-area">
    <v-alert
        class="alert"
        variant="outlined"
        :model-value="alert.getState().alerting"
        :type="alert.getState().type"
    >
      <span v-html="alert.getState().message" />
    </v-alert>
  </div>
</template>

<style lang="scss" scoped>
@import 'vuetify/lib/styles/main.sass';

.alert-area {
  @extend .d-flex;
  @extend .justify-center;
  @extend .align-center;
  position: fixed;
  top: 120px;
  width: 100%;

  .alert {
    margin: 0 20px;
    padding: 4px;
    background-color: white;
  }
}
</style>
