<script setup lang="ts">
import {useBackBtn} from "~/composables/useBackBtn";
import backIcon from "@/components/icons/BackIcon.vue"
import menuIcon from "@/components/icons/MenuIcon.vue"
import {useMenu} from "~/composables/useMenu";
import {useAppLogApi} from "~/server/utils/useAppLogApi";
import {useLogger} from "~/composables/useLogger";

const config = useRuntimeConfig()
const backBtn = useBackBtn()
const menu = useMenu()
const logger = useLogger()

// 注意：app.vueはページ遷移で再生成されないためonBeforeMountは1度しか呼ばれません
onBeforeMount(() => {
  // 右クリック禁止
  document.body.oncontextmenu = () => {
    return false
  }
  // 画面クリックでメニューを閉じる設定
  document.body.onclick = () => {
    menu.hide()
  }
  // ピンチイン・アウトを禁止
  document.body.addEventListener('touchmove', (e) => {
    if (e.touches.length > 1) {
      e.preventDefault()
    }
  }, {passive: false})

  // 高さ計算
  window.addEventListener('resize', setFillHeight)
  window.addEventListener('orientationchange', setFillHeight)
  setFillHeight()
})

onErrorCaptured((callback) => {
  logger.error(callback.message, callback)
})

/**
 * iOSでcssによるheightが正しく指定できない問題があるためJavascriptで計算しています
 */
function setFillHeight() {
  setTimeout(() => {
    const vh = window.innerHeight * 0.01; //ビューポートの高さを取得し、0.01を掛けて1%の値を算出して、vh単位の値を取得
    document.documentElement.style.setProperty('--vh', `${vh}px`); //カスタム変数--vhの値をドキュメントのルートに設定
  }, 100)
}

function onClickBackBtn() {
  useAppLogApi().execute({type: "click", clickName: "back"})
  history.back()
}

function onClickMenuBtn() {
  useAppLogApi().execute({type: "click", clickName: "menu"})
  menu.getState().visibleMenu ? menu.hide() : menu.show()
}

function onClickPrivacy() {
  useAppLogApi().execute({type: "click", clickName: "menu.privacy"})
  window.open("https://corporate.navitime.co.jp/policy/", '_blank')
}
</script>

<template>
  <v-app>
    <v-main>
      <!-- ページ -->
      <NuxtPage/>
      <!-- 戻るボタン -->
      <v-btn v-if="backBtn.getState().visible" class="back-btn" :icon="backIcon" @click="onClickBackBtn"/>
      <!-- アラート -->
      <Alert/>
      <!-- メニュー -->
      <v-btn v-if="menu.getState().visibleMenuBtn" class="menu-btn" :icon="menuIcon" @click.stop="onClickMenuBtn"/>
      <v-sheet v-if="menu.getState().visibleMenu" class="menu">
        <v-list>
          <v-list-item title="プライバシーポリシー" @click="onClickPrivacy"/>
          <v-list-item title="利用規約" @click="navigateTo('/terms')"/>
          <v-list-item title="使い方ガイド" @click="navigateTo('/usage')"/>
          <v-list-item title="アンケート" @click="navigateTo('/questionnaire')"/>
          <v-list-item title="ライセンス" @click="navigateTo('/licenses')"/>
          <v-list-item v-if="config.public.debug" @click="navigateTo('/debug')">
            <div class="d-flex align-center">
              <div class="d-flex align-center mr-1">
                <v-icon icon="mdi-bug" color="#4a81c7" size="small"/>
              </div>
              <div class="v-list-item-title">デバッグ</div>
            </div>
          </v-list-item>
        </v-list>
      </v-sheet>
      <!-- ローディング -->
      <Loading/>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
@import 'assets/styles/main.scss';

.v-application {
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  min-height: 100dvh; // iOS15.4以降用

  .v-main {

    .back-btn {
      display: block;
      position: fixed;
      top: 15px;
      left: 16px;
      background: transparent;
      box-shadow: none;
      width: 20px;
      height: 24px;
    }

    .menu-btn {
      display: block;
      position: fixed;
      top: 15px;
      right: 16px;
      background: transparent;
      box-shadow: none;
      width: 24px;
      height: 16px;
    }

    .menu {
      position: fixed;
      top: 22px;
      right: 20px;
      background-color: transparent;

      :deep(.v-list) {
        padding: 20px 0;
        border-radius: 10px 0 10px 10px;
        box-shadow: 0 2px 6px 0 $primary-color;

        .v-list-item {
          min-height: 0;

          .v-list-item-title {
            @extend .font-bold;
            font-size: 12px;
            color: $primary-color;
          }
        }
      }
    }
  }
}
</style>
