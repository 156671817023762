import {RouteLocationNormalized} from "vue-router";
import {useNaviLog} from "~/composables/useNaviLog";
import {useLogger} from "~/composables/useLogger";

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    // 画面遷移のタイミングで未送信のナビログを送信します
    const naviLog = useNaviLog()
    naviLog.sendAll()
        .catch((event: Event) => {
            useLogger().error("ナビログ送信エラー", event)
        })
})