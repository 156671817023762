import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_sentry_client_ts_shVUlIjFLk from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/plugins/sentry.client.ts";
import plugins_vuetify_client_ts_69rYAaPZnF from "/home/jenkins/agent/workspace/contents/aioikickboard/staging/web_deploy/aioikickboard/plugins/vuetify.client.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_sentry_client_ts_shVUlIjFLk,
  plugins_vuetify_client_ts_69rYAaPZnF
]