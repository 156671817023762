import {useNaviLogApi} from "~/server/utils/useNaviLogApi";
import {useUserState} from "~/composables/useUserState";
import {useLogger} from "~/composables/useLogger";

const naviLogApi = useNaviLogApi()

const DB_NAME = "ntj_ak_navi_log_db"
const DB_VERSION = 1
const NAVI_LOG_STORE_NAME = "main"
const NAVI_LOG_STORE_KEY_PATH = "id"
const NAVI_LOG_VERSION = 1
const MAX_RECORD_COUNT = 300

type Datum = 0 | 1 // 0：世界測地　1：日本測地
export type NaviLog = {
    id?: number // indexedDBで自動採番されたid
    ver: number // バージョン
    dm: Datum // 測地系
    fr?: string // 遷移元
    dpl?: [number, number] // 出発地 ([経度(lon),緯度(lat)]のミリ秒表記の配列)
    dsl?: [number, number] // 目的地 ([経度(lon),緯度(lat)]のミリ秒表記の配列)
    d: NaviLogData[]  // 位置情報データ
}
export type NaviLogData = {
    ts: number // タイムスタンプ(エポックミリ秒)
    l: [number, number] // センサーから取得した位置 ([経度(lon),緯度(lat)]のミリ秒表記の配列)
    ac?: number // 水平精度
    alt?: number | null // 高度
    dir?: number | null // 方位
}

export const useNaviLog = () => {
    return {
        save,
        sendAll,
    }
}

function openNaviLogDB(onsuccess: (store: IDBObjectStore) => void, storeName: string = NAVI_LOG_STORE_NAME) {
    const request: IDBOpenDBRequest = indexedDB.open(DB_NAME, DB_VERSION)

    request.onupgradeneeded = (event: Event) => {
        const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;
        db.createObjectStore(NAVI_LOG_STORE_NAME, {
            keyPath: NAVI_LOG_STORE_KEY_PATH,
            autoIncrement: true
        })
    }

    request.onsuccess = (event: Event) => {
        const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;
        const transaction: IDBTransaction = db.transaction(storeName, "readwrite")
        const store: IDBObjectStore = transaction.objectStore(storeName)

        if (onsuccess) {
            onsuccess(store)
        }
        transaction.oncomplete = () => {
            db.close()
        }
        transaction.onerror = () => {
            db.close()
        }
    }

    request.onerror = (event) => {
        useLogger().error(`indexedDB open error: ${JSON.stringify(event)}`)
    }
}

/**
 * ナビログ保存
 *
 * @param dpl 出発地 ([経度(lon),緯度(lat)]のミリ秒表記の配列)
 * @param dsl 目的地 ([経度(lon),緯度(lat)]のミリ秒表記の配列)
 * @param dm 測地系
 * @param naviLogRecord 位置情報データ
 */
function save(dpl: [number, number],
              dsl: [number, number],
              dm: Datum,
              naviLogData: NaviLogData) {

    naviLogData.ac = naviLogData.ac ? ~~naviLogData.ac : -1
    naviLogData.alt = naviLogData.alt ? ~~naviLogData.alt : -1
    naviLogData.dir = naviLogData.dir ? ~~naviLogData.dir : -1

    const newNaviLog: NaviLog = {
        ver: NAVI_LOG_VERSION,
        dm: dm,
        dpl: dpl,
        dsl: dsl,
        d: [naviLogData],
        fr: useUserState().getFrom()
    }

    getAll().then(async (naviLogList?: NaviLog[]) => {
        // ナビログデータが存在しない場合
        if (!naviLogList || naviLogList.length === 0) {
            await putNaviLog(newNaviLog)
            return
        }

        // レコード数が閾値未満の場合
        const latestNaviLog = naviLogList.pop()
        const sendNaviLogList = [...naviLogList]
        if (latestNaviLog) {
            if (latestNaviLog.d.length < MAX_RECORD_COUNT) {
                latestNaviLog.d.push(naviLogData)
                await putNaviLog(latestNaviLog)
            }
            // レコード数が閾値超えの場合
            else {
                // 新しいナビログデータの登録
                await putNaviLog(newNaviLog)
                // ナビログデータの送信対象に追加
                sendNaviLogList.push(latestNaviLog)
            }
        }

        // 送信対象のデータがある場合
        await send(sendNaviLogList)
    })
}

function putNaviLog(naviLog: NaviLog) {
    openNaviLogDB((store: IDBObjectStore) => {
        const request: IDBRequest<IDBValidKey> = store.put(naviLog)
        request.onerror = (event: Event) => {
            console.error(event)
        }
    })
}

function _delete(key?: number) {
    if (!key) return

    return new Promise<Event>(
        (
            resolve: (event: Event) => void,
            reject: (event: Event) => void,
        ) => {
            openNaviLogDB((store: IDBObjectStore) => {
                const request: IDBRequest = store.delete(key)
                request.onsuccess = (event: Event) => {
                    resolve(event)
                }
                request.onerror = (event: Event) => {
                    reject(event)
                }
            })
        }
    )
}

function getAll(): Promise<NaviLog[]> {
    return new Promise<NaviLog[]>(
        (
            resolve: (naviLogList: NaviLog[]) => void,
            reject: (event: Event) => void,
        ) => {
            openNaviLogDB((store: IDBObjectStore) => {
                const request: IDBRequest<NaviLog[]> = store.getAll()
                request.onsuccess = (event: Event) => {
                    const naviLogList = (event.target as IDBRequest<NaviLog[]>).result;
                    resolve(naviLogList)
                }
                request.onerror = (event: Event) => {
                    reject(event)
                }
            })
        }
    )
}

async function send(naviLogList: NaviLog[]) {
    for (const naviLog of naviLogList) {
        // ナビログデータの送信
        const ok = await naviLogApi.execute(naviLog)
        // ナビログデータの削除
        if (ok) {
            await _delete(naviLog.id)
        }
    }
}

async function sendAll() {
    getAll().then(async (naviLogList?: NaviLog[]) => {
        // ナビログデータが存在しない場合
        if (!naviLogList || naviLogList.length === 0) {
            return
        }

        await send(naviLogList)
    })
}