import * as pkg from '~/package.json'
import * as Sentry from "@sentry/vue"

export default defineNuxtPlugin((nuxtApp) => {
    const {vueApp} = nuxtApp

    Sentry.init({
        app: [vueApp],
        dsn: nuxtApp.$config.public.sentryDsn,
        environment: nuxtApp.$config.public.environment,
        release: pkg.version,
        beforeSend(event, hint) {
            // Check if it is an exception, and if so, log it.
            if (event.exception) {
                // eslint-disable-next-line no-console
                console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, {event, hint});
            }
            // Continue sending to Sentry
            return event;
        }
    })

    Sentry.attachErrorHandler(vueApp, {
        logErrors: false,
        attachProps: true,
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update']
    })

    return {
        provide: {
            sentrySetContext: Sentry.setContext,
            sentrySetUser: Sentry.setUser,
            sentrySetTag: Sentry.setTag,
            sentryAddBreadcrumb: Sentry.addBreadcrumb,
            sentryCaptureException: Sentry.captureException
        }
    }
})
