type State = {
    visibleMenuBtn: boolean;
    visibleMenu: boolean;
}

export const useMenu = () => {
    const state = useState<State>("menu", () => ({
        visibleMenuBtn: false,
        visibleMenu: false,
    }));
    return {
        getState: () => readonly(state.value),
        showBtn: () => state.value.visibleMenuBtn = true,
        hideBtn: () => state.value.visibleMenuBtn = false,
        show: () => state.value.visibleMenu = true,
        hide: () => state.value.visibleMenu = false,
    };
};