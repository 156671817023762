import {Ref} from "vue";
import dayjs from "dayjs";

type LogType = "debug" | "info" | "warn" | "error"
type Log = {
    type: LogType
    time: string
    data: any
    error?: Error
}
type State = {
    logs: Log[]
}
const MAX_LOGS = 100

export const useLogger = () => {
    const state = useState<State>("logger", () => ({
        logs: []
    }))
    return {
        getState: () => readonly(state.value),
        debug: (data: any) => {
            pushLog(state, "debug", data)
        },
        info: (data: any) => {
            pushLog(state, "info", data)
        },
        warn: (data: any) => {
            pushLog(state, "warn", data)
        },
        error: (data: any, event?: Error) => {
            pushLog(state, "error", data, event)
        }
    }
}

function pushLog(state: Ref<State>, type: LogType, data: any, error?: Error) {
    const time = dayjs().format()
    state.value.logs.push({
        type: type,
        time: time,
        data: data,
        error: error
    })
    if (state.value.logs.length > MAX_LOGS) {
        state.value.logs.shift()
    }

    if (useRuntimeConfig().public.debug) {
        const message = `[${time}] ${data}`
        switch (type) {
            case "debug":
                console.debug(message)
                break
            case "info":
                console.info(message)
                break
            case "warn":
                console.warn(message)
                break
            case "error":
                error ? console.error(message, error) : console.error(message)
                break
        }
    }
}