import {cookieConst} from "~/consts/cookieConst";
import {useCookieApi} from "~/server/utils/useCookieApi";
import {useLogger} from "~/composables/useLogger";
import {AreaType} from "~/utils/useBirdUtil";

type State = {
    from?: string // 遷移元パラメータ
    areaType?: AreaType
}
const MAX_FROM_LENGTH = 30

export type CommonCookie = {
    notShowAgainAttentionDialog: boolean
}

export const useUserState = () => {
    const state = useState<State>("userState", () => ({}))
    return {
        getUUID: () => useCookie(cookieConst.NAME_UUID).value,
        getFrom: () => {
            const fromCookie = useCookie(cookieConst.NAME_FROM).value
            return fromCookie ?? readonly(state.value).from
        },
        setFrom: (from: string) => {
            state.value.from = from.substring(0, MAX_FROM_LENGTH)
        },
        getAreaType: (): AreaType | undefined => readonly(state.value).areaType,
        setAreaType: (areaType: AreaType) => {
          state.value.areaType = areaType
        },
        getNotShowAgainAttentionDialog: (): boolean => getCommon().notShowAgainAttentionDialog,
        setNotShowAgainAttentionDialog: (flag: boolean) => setCommonCookie({"notShowAgainAttentionDialog": flag})
    }
}

function getCommon(): CommonCookie {
    const encodedCommon = useCookie(cookieConst.NAME_COMMON).value
    if (!encodedCommon) {
        return {
            notShowAgainAttentionDialog: false
        }
    }

    return JSON.parse(atob(encodedCommon))
}

async function setCommonCookie(commonCookie: CommonCookie) {
    const logger = useLogger()
    const updatesCommonCookie = {...getCommon(), ...commonCookie}

    useCookieApi().execute({
        name: cookieConst.NAME_COMMON,
        value: btoa(JSON.stringify(updatesCommonCookie))
    })
        .then(() => {
            logger.debug(`commonのCookie保存に成功`)
        })
        .catch((event) => {
                logger.error(`commonのCookie保存に失敗`, event)
            }
        )
}
