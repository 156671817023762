import {AppLogQuery, useAppLogApi} from "~/server/utils/useAppLogApi";
import {RouteLocationNormalized} from "vue-router";

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    // PV/UU集計用のリクエスト
  const userState = useUserState()
  const query: AppLogQuery = {
        type: "view",
        pagePath: to.path,
        pageStatus: to.matched.length === 0 ? "404" : "200",
        pageQuery: new URLSearchParams(JSON.parse(JSON.stringify(to.query))).toString(),
        fromPagePath: from.path,
        fromPageStatus: from.matched.length === 0 ? "404" : "200",
        fromPageQuery: new URLSearchParams(JSON.parse(JSON.stringify(from.query))).toString(),
        areaType: userState.getAreaType()

    }
    useAppLogApi().execute(query)
})
