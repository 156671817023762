type State = {
    visible: boolean;
};

export const useBackBtn = () => {
    const state = useState<State>("backBtn", () => ({
        visible: false,
    }));
    return {
        getState: () => readonly(state.value),
        show: () => state.value.visible = true,
        hide: () => state.value.visible = false,
    };
};