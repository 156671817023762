import {useMyFetch} from "~/composables/useMyFetch";
import {NaviLog} from "~/composables/useNaviLog";

export const useNaviLogApi = () => {
    return {
        execute: <T>(body: NaviLog): Promise<boolean> => {
            return new Promise(
                (
                    resolve: (ok: boolean) => void,
                    reject: () => void,
                ) => {
                    const url = `/api/v1/navi/log`
                    useMyFetch(url).post(body)
                        .then(({response}) => {
                            if (response.value?.ok) {
                                resolve(true)
                            } else {
                                reject()
                            }
                        })
                }
            )
        }
    }
}