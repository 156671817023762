import {useMyFetch} from "~/composables/useMyFetch";

export type CookieQuery = {
    name: string
    value?: string
}

export const useCookieApi = () => {
    return {
        execute
    }
}

function execute(query: CookieQuery): Promise<boolean> {
    return new Promise(
        (
            resolve: (ok: boolean) => void,
            reject: () => void,
        ) => {
            const params = new URLSearchParams(query)
            const url = `/api/v1/app/cookie?${params.toString()}`
            useMyFetch(url)
                .get()
                .then(({response}) => {
                    if (response.value?.ok) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
        })
}