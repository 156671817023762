type State = {
    isActive: boolean
}

export const useLoading = () => {
    const state = useState<State>("loading", () => ({
        isActive: false,
    }))
    return {
        getState: () => readonly(state.value),
        start: () => state.value.isActive = true,
        stop: () => state.value.isActive = false,
    }
}